<div class="modal-header">
  <h3 class="modal-title m-0">{{data.titulo}}</h3>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="onNoClick()"
  ></button>
</div>
<div class="modal-body">
  <p>{{ data.pergunta }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onNoClick()">
    {{data.noString ? data.noString : 'Não'}}
  </button>
  &nbsp;&nbsp;
  <button type="button" class="btn btn-primary mat-primary" (click)="onClick()">
    {{data.yesString ? data.yesString : 'Sim'}}
  </button>
</div>
