import { ProfileTypeEnum } from "../enums/profily_type";
import { DbEntity } from "./db_entity";

export class User extends DbEntity {
    userId: number;
    username: string;
    password: string;
    profiles: number[];
    functionalities: number[];
    typeProfiles: ProfileTypeEnum[];
    lastAccessDls: string;
    shortName: string;

    constructor(username: string, password: string) {
        super();

        this.username = username;
        this.password = password;
    }
}
