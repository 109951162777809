import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  getData(key: string): any {
    return this.stringToJson(localStorage.getItem(key));
  }

  setData(key: string, data: any) {
    localStorage.setItem(key, this.jsonToString(data));
  }

  deleteData(key: string) {
    localStorage.removeItem(key);
  }

  deleteAll() {
    localStorage.clear();
  }

  private stringToJson(data?: string) {
    if (data && data != 'undefined') {
      try {
        let values = JSON.parse(data);
        for(const key in values) {
          values[key] = JSON.parse(values[key]);
        }
        return values;
      } catch (error) {
        console.log('Error');
        console.log(error);
      }
    }
  }

  private jsonToString(data: any): string {
    try {
      return JSON.stringify(data);
    } catch (error) {
      let values = {};
      for (const item in data) {
        if (typeof(data[item]) === 'object') {
          values[item] = JSON.stringify(data[item].value);
        } else {
          values[item] = JSON.stringify(data[item]);
        }
      }
      return JSON.stringify(values);
    }
  }
}
