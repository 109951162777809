import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'azure/login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
      canActivate: [AuthGuard],
  },
  {
    path: 'ajuda',
    loadChildren: () =>
      import('./pages/ajuda/ajuda.module').then((m) => m.AjudaModule),
      canActivate: [AuthGuard],
  },
  {
    path: 'perfil-acesso',
    loadChildren: () =>
      import('./pages/perfil-acesso/perfil-acesso.module').then(
        (m) => m.PerfilAcessoModule
      ),
      canActivate: [AuthGuard],
  },
  {
    path: 'usuario-login',
    loadChildren: () =>
      import('./pages/usuario-login/usuario-login.module').then(
        (m) => m.UsuarioLoginModule
      ),
      canActivate: [AuthGuard],
  },
  {
    path: 'permissao-acesso',
    loadChildren: () =>
      import('./pages/permissao-acesso/permissao-acesso.module').then(
        (m) => m.PermissaoAcessoModule
      ),
      canActivate: [AuthGuard],
  },
  {
    path: 'configuracoes',
    loadChildren: () =>
      import('./pages/configuracoes/configuracoes.module').then(
        (m) => m.ConfiguracoesModule
      ),
      canActivate: [AuthGuard],
  },
  {
    path: 'log-atividades',
    loadChildren: () =>
      import('./pages/log-atividades/log-atividades.module').then(
        (m) => m.LogAtividadesModule
      ),
      canActivate: [AuthGuard],
  },
  {
    path: 'daily-check',
    loadChildren: () =>
      import('./pages/daily-check/daily-check.module').then(
        (m) => m.DailyCheckModule
      ),
      canActivate: [AuthGuard],
  },
  {
    path: 'posicionamento',
    loadChildren: () =>
      import('./pages/posicionamento/posicionamento.module').then(
        (m) => m.PosicionamentoModule
      ),
      canActivate: [AuthGuard],
  },
  {
    path: 'aeroporto',
    loadChildren: () =>
      import('./pages/aeroporto/aeroportos.module').then(
        (m) => m.AeroportoModule
      ),
      canActivate: [AuthGuard],
  },
  {
    path: 'cadastros-geral',
    loadChildren: () =>
      import('./pages/cadastros-geral/cadastros-geral.module').then(
        (m) => m.CadastrosGeralModule
      ),
      canActivate: [AuthGuard],
  },
  {
    path: 'duplicidade-manutencao',
    loadChildren: () =>
      import('./pages/duplicidade-manutencao/duplicidade-manutencao.module').then(
        (m) => m.DuplicidadeManutencaoModule
      ),
      canActivate: [AuthGuard],
  },
  {
    path: 'posicionamento-concluido',
    loadChildren: () =>
      import('./pages/posicionamento-concluido/posicionamento-concluido.module').then(
        (m) => m.PosicionamentoConcluidoModule
      ),
      canActivate: [AuthGuard],
  },
  {
    path: 'daily-check-concluido',
    loadChildren: () =>
      import('./pages/daily-check-concluido/daily-check-concluido.module').then(
        (m) => m.DailyCheckConcluidoModule
      ),
      canActivate: [AuthGuard],
  },
  {
    path: 'base-pernoite',
    loadChildren: () =>
      import('./pages/base-pernoite/base-pernoite.module').then(
        (m) => m.BasePernoiteModule
      ),
      canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true , onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
