<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" opened="false" [class.mat-elevation-z4]="true">
    <mat-nav-list dense>
      <ng-container *ngFor="let menuItem of menu.items">
        <mat-list-item routerLink="{{menuItem.route}}" routerLinkActive="is-active">
          <mat-icon>{{menuItem.icon}}</mat-icon>{{menuItem.label}}
        </mat-list-item>
      </ng-container>

      <ng-container *ngFor="let menuExpansibleItem of menu.expansibleItems;">
        <mat-expansion-panel [class.mat-elevation-z0]="true" dense [expanded]="menuExpansibleItem.title === 'Home' ? true : false">
          <mat-expansion-panel-header>
            <mat-icon>{{menuExpansibleItem.icon}}</mat-icon>{{menuExpansibleItem.title}}
          </mat-expansion-panel-header>
          <mat-nav-list dense>
            <ng-container *ngFor="let menuItem of menuExpansibleItem.items">
              <a mat-list-item routerLink="{{menuItem.route}}" routerLinkActive="is-active">
                <mat-icon>{{menuItem.icon}}</mat-icon>{{menuItem.label}}
              </a>
            </ng-container>
          </mat-nav-list>
        </mat-expansion-panel>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content style="padding-left: 2rem;">
    <ng-content></ng-content>
    <br><br>
  </mat-sidenav-content>
</mat-sidenav-container>
