import { Injectable } from "@angular/core";
import { LoginResponse } from "../../shared/model/http/responses/login_response";

@Injectable({ providedIn: 'root' })
export class SessionService {
  get(): LoginResponse {
    return JSON.parse(localStorage.getItem('session')) as LoginResponse;
  }

  getToken(): string {
    return this.get()?.token;
  }
}