<mat-toolbar class="bgOrange">
  <button mat-icon-button (click)="toggleSideNav()" *ngIf="loginService.isAuthenticated()">
    <mat-icon *ngIf="openSideNav">menu</mat-icon>
    <mat-icon *ngIf="!openSideNav">menu_open</mat-icon>
  </button>
  <span style="color: var(--color-surface); font-weight: bold;">[Pit Lane]</span>
  <span style="flex: 1 1 auto; margin-left: 33%; color: var(--color-surface); font-size: 25px;">Sistema de Posicionamento Aéreo</span>
  <div>
    <button mat-icon-button matTooltip="Notificações" [matMenuTriggerFor]="notificationMenu" *ngIf="loginService.isAuthenticated()">
      <!-- <mat-icon [matBadge]="5" matBadgeColor="warn" -->
      <mat-icon [matBadge]="spaNotifications.unreadNotifications.length" matBadgeColor="warn"
        [matBadgeHidden]="spaNotifications.unreadNotifications.length == 0">notifications</mat-icon>
    </button>
    <mat-menu #notificationMenu [overlapTrigger]="false" yPosition="below">
      <div *ngFor="let notification of spaNotifications.unreadNotifications" class="wrapperNotification">
        <div (click)="sendToPage(notification)" class="d-flex pointer">
          <div class="wrapperAlert">
            <div [class]="getNotificationWarning(notification)"></div>
          </div>
          <div class="wrapperMessage ps-2">
            <p>{{ notification.message }}</p>
            <div *ngIf="notification.dailyCheckId != null">
              <p class="textDate">Data vencimento: {{ notification.getScheduleDate }}</p>
            </div>
            <div *ngIf="notification.dailyCheckId == null">
              <p class="textDate">Data programação: {{ notification.getScheduleDate }}</p>
            </div>
            <p class="textDate">Data posicionamento: {{ notification.getPositioningDate }}</p>
          </div>
          <div (click)="markNotificationAsRead(notification, $event)" class="wrapperButtonDone">
            <button class="btnDone">
              <mat-icon>done_all</mat-icon>
            </button>
          </div>
        </div>
        <div
          *ngIf="getNotificationWarning(notification) == 'redAlert' && notification.dailyCheckId == null && (notification.message.includes('NÃO POSICIONADO') || notification.message.includes('CANCELADO'))"
          class="wrapperButtons">
          <button (click)="reschedulePositioning(notification, true)" class="btn btn-primary mat-primary">Reagendar</button>
        </div>
      </div>

    </mat-menu>
    <button mat-icon-button matTooltip="Deslogar" [matMenuTriggerFor]="accountMenu"
      *ngIf="loginService.isAuthenticated()">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #accountMenu [overlapTrigger]="false" yPosition="below">
      <button mat-menu-item>
        <mat-icon>person</mat-icon>{{this.user?.shortName}} ({{this.user?.lastAccessDls}})
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>Sair
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
<mat-progress-bar mode="query" class="accenteBg" *ngIf="loadingService.isLoading"></mat-progress-bar>
