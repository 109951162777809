import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginResponse } from '../../shared/model/http/responses/login_response';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const session: LoginResponse = JSON.parse(localStorage.getItem('session'));
        const user = session?.user;
        return next.handle(request);
    }
}

@Injectable()
export class HttpsResponseInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(map(event => {
            return event;
        }));
    }
}

@NgModule({
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpsRequestInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpsResponseInterceptor, multi: true }
    ]
})
export class InterceptorModule { }
