import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Menu } from '../../shared/model/menu.model';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  menu: Menu = new Menu();
  public shouldUpdateMenu: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  constructor() {}
  getMenuList(userFunctionalities: number[]): Menu {
    this.menu = {
      expansibleItems: [
        {
          title: 'Home',
          icon: 'home',
          canShow: true,
          items: [
            // {
            //   label: 'Home',
            //   route: '/home',
            //   icon: 'home ',
            //   canShow: true,
            // },
            {
              label: 'Posicionamento Aeronave Pendente',
              route: '/posicionamento/listar',
              icon: 'airplanemode_active',
              canShow: true,
            },
            {
              label: 'Posicionamento Aeronave Concluído',
              route: '/posicionamento-concluido/listar',
              icon: 'airplanemode_active',
              canShow: true,
            },
            {
              label: 'Daily Check Pendente',
              route: '/daily-check/listar',
              icon: 'today ',
              canShow: true,
            },
            {
              label: 'Daily Check Concluído',
              route: '/daily-check-concluido/listar',
              icon: 'today ',
              canShow: true,
            },
          ],
        },
        {
          title: 'Alterações de Posicionamento',
          icon: 'sync',
          canShow: true,
          items: [
            {
              label: 'Posicionamento Aeronave',
              route: '/posicionamento/acoes',
              icon: 'airplanemode_active',
              canShow: true,
            },
            {
              label: 'Daily Check',
              route: '/daily-check/acoes',
              icon: 'today',
              canShow: true,
            },
          ],
        },        
        {
          title: 'Histórico',
          icon: 'history',
          canShow: true,
          items: [
            {
              label: 'Posicionamento Aeronave',
              route: '/posicionamento/historico',
              icon: 'airplanemode_active',
              canShow: true,
            },
            {
              label: 'Daily Check',
              route: '/daily-check/historico',
              icon: 'today',
              canShow: true,
            },
          ],
        },
        {
          title: 'Cadastro',
          icon: 'add',
          canShow: true,
          items: [
            {
              label: 'Cadastros',
              route: '/cadastros-geral',
              icon: 'add',
              canShow: true,
            },
            {
              label: 'Bases de Manutenção',
              route: '/aeroporto',
              icon: 'foundation',
              canShow: true,
            },
            {
              label: 'Duplicidades de Manutenção',
              route: '/duplicidade-manutencao',
              icon: 'block',
              canShow: true,
            },
            // {
            //   label: 'Bases de Pernoite',
            //   route: '/base-pernoite',
            //   icon: 'flight_land',
            //   canShow: true,
            // },
          ],
        },
        {
          title: 'Administrador',
          icon: 'settings',
          canShow: true,
          items: [
            {
              label: 'Perfil de Acesso',
              route: '/perfil-acesso',
              icon: 'person',
              canShow: true,
            },
             {
              label: 'Permissão de Acesso',
              route: '/permissao-acesso',
              icon: 'check_circle',
              canShow: true,
            },
            {
              label: 'Login Usuarios',
              route: '/usuario-login',
              icon: 'supervisor_account',
              canShow: true,
            },
            // {
            //   label: 'Configuracões',
            //   route: '/configuracoes',
            //   icon: 'engineering',
            //   canShow: true,
            // },
          ],
        },
        // {
        //   title: 'Relatórios',
        //   icon: 'article',
        //   canShow: true,
        //   items: [
        //     {
        //       label: 'Logs',
        //       route: '/log-atividades',
        //       icon: 'format_list_bulleted',
        //       canShow: true,
        //     },
        //   ],
        // },
      ],
    };
    return this.menu;
  }

  private canShow(
    userFunctionalities: number[],
    componentFunctionalityIds: number[]
  ) {
    const componentIds = {};

    for (const id of componentFunctionalityIds) componentIds[id] = true;

    for (const userFunctionalityId of userFunctionalities)
      if (componentIds[userFunctionalityId]) return true;

    return false;
  }
}
