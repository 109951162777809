import { NgModule } from "@angular/core";
import { HeaderComponent } from "./components/header/header.component";
import { SideNavComponent } from "./components/side-nav/side-nav.component";
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from "@angular/router";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatExpansionModule } from "@angular/material/expansion";

@NgModule({
    declarations: [HeaderComponent, SideNavComponent],
    imports: [
        CommonModule,
        MatListModule,
        MatToolbarModule,
        MatProgressBarModule,
        MatButtonModule,
        MatIconModule,
        MatBadgeModule, // Necessário para notificações
        MatTooltipModule,
        MatMenuModule,
        RouterModule,
        MatSidenavModule,
        MatExpansionModule
    ],
    exports: [HeaderComponent, SideNavComponent],
    entryComponents: []
  })
  export class CoreModule { }
