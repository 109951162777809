import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-base-field',
  templateUrl: './base-field.component.html',
  styleUrls: ['./base-field.component.scss']
})
export class BaseFieldComponent implements OnInit {
  private subjectPesquisaBase: Subject<string> = new Subject<string>();

  @Input() inputValue:string;
  @Input() showValidation = false;
  @Input() isDisabled = false;
  @Output() airportSelected = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onKey(termo: string, isObra?: boolean): void {
    if (termo.length >= 2) {
      this.subjectPesquisaBase.next(termo);
    }
  }

  airportSelect(airport): void {
    this.airportSelected.emit(airport.value);
  }

  buscarAirportData(): void {
    const airportsData = JSON.parse(localStorage.getItem('airports'));
    if (airportsData) {
    }
  }

}
