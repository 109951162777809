import { HttpDataSource } from "../data_source/config";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { SessionService } from "./session.service";
import { Configuracoes } from "src/app/shared/model/configuracoes.model";

@Injectable({ providedIn: 'root' })
export class ConfiguracoesService {
  constructor(
    private dataSource: HttpDataSource,
    private sessionService: SessionService
  ) {
  }

  getById(ConfiguracoesId: number) {
    return this.dataSource.get<Configuracoes>(`${environment.apiUrl}/Settings/${ConfiguracoesId}`, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  getDateTimeServer() {
    return this.dataSource.get<string>(`${environment.apiUrl}/Settings/GetDateTimeServer`, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  update(Configuracoes: Configuracoes): Observable<Configuracoes> {
    return this.dataSource.put<Configuracoes>(`${environment.apiUrl}/Settings`, Configuracoes, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

}
