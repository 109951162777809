
import { HttpDataSource } from '../data_source/config';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginResponse } from '../../shared/model/http/responses/login_response';
import { User } from '../../shared/model/user.model';
import { ExternalAuthenticateModel, ExternalAuthenticateResultModel } from 'src/app/shared/services/service-proxies';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(
    private router: Router,
    private dataSource: HttpDataSource,
  ) { }

  isAuthenticated(): string {
    return localStorage.getItem('session');
  }

  login(user: User): Observable<LoginResponse> {
    user = new User(user.username, user.password);

    return this.dataSource.post<LoginResponse>(`${environment.apiUrl}/Login`, user);
  }

  externalLogin(model: string): Observable<ExternalAuthenticateResultModel> {
    const token = {token: model};
    return this.dataSource.post<ExternalAuthenticateResultModel>(`${environment.apiUrl}/Login/externalLogin`, token);
  }

  logout(): void {
    this.router.navigate(['/azure/login']);
    localStorage.clear();
  }
  
}
