import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../core/components/header/header.component';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { BaseFieldComponent } from './components/base-field/base-field.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { ModalConfirmacaoComponent } from './components/modal-confirmacao/modal-confirmacao.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomAutocompleteComponent } from './components/custom-autocomplete/custom-autocomplete.component';
import { MatSelectSearchComponent } from './components/mat-select-search/mat-select-search.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { ModalDetailDailyCheckComponent } from './components/modal-upload-daily-check/modal-detail-daily-check.component';
import { A11yModule } from '@angular/cdk/a11y'

const COMPONETS = [
    BaseFieldComponent,
    DataTableComponent,
    ModalConfirmacaoComponent,
    ModalDetailDailyCheckComponent,
    CustomAutocompleteComponent,
    MatSelectSearchComponent
];

@NgModule({
    declarations: [COMPONETS, CustomAutocompleteComponent],
    imports: [
      CommonModule,
      NgSelectModule,
      FormsModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatAutocompleteModule,
      MatSelectModule,
      MatTableExporterModule,
      MatButtonModule,
      MatNativeDateModule,
      MatTableModule,
      MatCheckboxModule,
      MatIconModule,
      MatTooltipModule,
      MatProgressSpinnerModule,
      MatPaginatorModule,
      MatSortModule,
      MatSnackBarModule,
      MatDialogModule,
      MatListModule,
      MatToolbarModule,
      MatProgressBarModule,
      MatMenuModule,
      RouterModule,
      MatSidenavModule,
      MatExpansionModule,
      MatCardModule,
      MatDatepickerModule,
      A11yModule
    ],
    exports: [COMPONETS, MatButtonModule, MatInputModule],
    entryComponents: [
        ModalConfirmacaoComponent
      ]
  })
  export class SharedModule { }
