<mat-form-field>
  <input matInput
    type="text"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value">
      {{ option.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
