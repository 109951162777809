import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SideNavComponent } from './core/components/side-nav/side-nav.component';
import { SpaNotificationService } from './core/services/spa-notification.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'gol-rest_ops-ui';

  @ViewChild('sidenavComponent') sidenavComponent: ElementRef<SideNavComponent>;

  constructor(private router: Router, public spaNotification: SpaNotificationService) {}
  ngOnInit(): void {
    this.cron();
  }

  cron() {
    let self = this;
    self.spaNotification.updateNotifications();
    setInterval(function() {
      self.spaNotification.updateNotifications();
    }, 1000 * 10);
  }

  navigate(): void {
    this.router.navigate(['/azure/login']);
  }

  toggleSideNav(toggle): void {
    this.sidenavComponent['sidenav'].toggle();
  }
}
