import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, retry, tap } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { LoadingService } from "../services/loading.service";
import { SessionService } from "../services/session.service";
import { environment } from "src/environments/environment";
import { LoginResponse } from "../../shared/model/http/responses/login_response";

@Injectable({providedIn: 'root'})
export class HttpDataSource {
  constructor(
    private sessionService: SessionService,
    private http: HttpClient,
    protected loading: LoadingService
  ) {}

  /**
   * Constructs a `GET` request that interprets the body as a JSON object and returns
   * the response body in a given type.
   *
   * @param url     The endpoint URL.
   * @param options The HTTP options to send with the request.
   *
   * @return An `Observable` of the `HTTPResponse`, with a response body in the requested type.
  */
  get<T>(url: string, options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }, retryCount?: number, handleError = true): Observable<T> {
    if (!options) {
      options = { headers: {} };
    }
    if (!options.headers) {
      options.headers = {};
    }
    options.headers['Access-Control-Allow-Origin'] = '*';
    try {
      return this.http.get<T>(url, options)
        .pipe(tap(() => this.afterCall(HttpMethod.get)), catchError(this.errorHandling),retry(retryCount ?? 1));
    } catch (error) {
      if (handleError) {
        this.errorHandling(error);
      }
    }
  }

  /**
   * Constructs a `POST` request that interprets the body as a JSON object
   * and returns an observable of the response.
   *
   * @param url The endpoint URL.
   * @param body The content to replace with.
   * @param options HTTP options
   *
   * @return  An `Observable` of the `HTTPResponse` for the request, with a response body in the
   *     requested type.
  */
  post<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }, retryCount?: number): Observable<T> {
    if (!options) {
      options = { headers: {} };
    }
    if (!options.headers) {
      options.headers = {};
    }
    options.headers['Access-Control-Allow-Origin'] = '*';
    try {
      // setInterval(() => this.afterCall(HttpMethod.post), 3000);
      return this.http.post<T>(url, body, options)
        .pipe(tap(() => this.afterCall(HttpMethod.post)), catchError(this.errorHandling),retry(retryCount ?? 1));
    } catch (error) {
      this.errorHandling(error);
    }
  }

  /**
   * Constructs a `PUT` request that interprets the body as an instance of the requested type
   * and returns an observable of the requested type.
   *
   * @param url The endpoint URL.
   * @param body The resources to add/update.
   * @param options HTTP options
   *
   * @return An `Observable` of the requested type.
  */
  put<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }, retryCount?: number): Observable<T> {
    if (!options) {
      options = { headers: {} };
    }
    if (!options.headers) {
      options.headers = {};
    }
    options.headers['Access-Control-Allow-Origin'] = '*';
    try {
      return this.http.put<T>(url, body, options)
        .pipe(tap(() => this.afterCall(HttpMethod.put)), catchError(this.errorHandling),retry(retryCount ?? 1));
    } catch (error) {
      this.errorHandling(error);
    }
  }

  /**
   * Constructs a DELETE request that interprets the body as a JSON object and returns
   * the response in a given type.
   *
   * @param url     The endpoint URL.
   * @param options The HTTP options to send with the request.
   *
   * @return An `Observable` of the `HTTPResponse`, with response body in the requested type.
  */
  delete<T>(url: string, options?: {
      headers?: HttpHeaders | {
          [header: string]: string | string[];
      };
      observe?: 'body';
      params?: HttpParams | {
          [param: string]: string | string[];
      };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
  }, retryCount?: number): Observable<T> {
    if (!options) {
      options = { headers: {} };
    }
    if (!options.headers) {
      options.headers = {};
    }
    options.headers['Access-Control-Allow-Origin'] = '*';
    try {
      return this.http.delete<T>(url, options)
        .pipe(tap(() => this.afterCall(HttpMethod.delete)), catchError(this.errorHandling),retry(retryCount ?? 1));
    } catch (error) {
      this.errorHandling(error);
    }
  }

  private afterCall(method: HttpMethod) {
    try {
      if (method != HttpMethod.get) {
        // Tenta fazer o Relogin
        this.http.get<LoginResponse>(
          `${environment.apiUrl}/Login/Relogin`,
          { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'} }
        ).subscribe(response => {
          localStorage.setItem('session', JSON.stringify(response));
        });
      }
    } catch (error) {
      console.log('Error on Before Call');
      console.log(error);
    }
  }

  private errorHandling(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          localStorage.clear();
          window.location.href = '/azure/login';
          this.loading.showLoading(false);
          return;
        } else {
          console.log(`================ Error ${error.status} ================`);
          console.log(error.message);
        }
      } else {
        console.log("================ Erro desconhecido ================");
        console.log(error);
      }
    }
    let message = '';

    if(error.status === 403){
       message = 'Você não possui permissão para realizar esta ação';
    }else{
       message = (typeof error.error == 'string' ? error.error : 'Erro desconhecido');
    }

    return throwError(() => { return new CustomError(message, error); });

  }
}

enum HttpMethod {
  get, post, put, delete
}

class CustomError {
  message: string;
  error: string;
  fullError: any;
  constructor(message: string, fullError: any) {
    this.error = message;
    this.message = message;
    this.fullError = fullError;
  }

  public toString = () : string => {
    return this.message;
  }
}
