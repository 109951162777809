<div class="modal-header">
  <h3 class="modal-title m-0">{{data.titulo}}</h3>
  <button type="button" class="btn-close" aria-label="Close" (click)="onNoClick()"></button>
</div>
<div class="modal-body">
  <span>Total linhas para importação: {{data.detalhes.length}}</span>
  <br>
  <span>Total linhas importadas com sucesso: {{data.detalhes.length - data.dadosError.length}}</span>
  <br>

  <span>Total de linhas com falha: {{data.dadosError.length}}</span>
  <br>
  <ng-container *ngFor="let item of this.data.dadosError">
    {{item}} <br>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary mat-primary" (click)="onClick()">
    {{data.yesString ? data.yesString : 'Fechar'}}
  </button>
</div>
