import { Injectable } from '@angular/core';
import { HttpDataSource } from 'src/app/core/data_source/config';
import { Integer } from 'src/app/core/helpers/integer';
import { environment } from "src/environments/environment";
import { SessionService } from 'src/app/core/services/session.service';
import { SpaNotification } from '../../shared/model/spa-notification.model';

@Injectable({
  providedIn: 'root'
})
export class SpaNotificationService {
  public notifications: SpaNotification[] = [];

  constructor(
    private dataSource: HttpDataSource,
    private sessionService: SessionService,
  ) {}

  get unreadNotifications(): SpaNotification[] {
    return this.notifications?.filter(e => !e.read) ?? [];
  }

  updateNotifications() {
    let self = this;
    if (this.sessionService.getToken()) {
      this.getAll().subscribe({
        next: (res) => {
          if(res != null)
            self.notifications = res.map(e => new SpaNotification().deserialize(e));
        },
        error: (err) => {
          console.log('============== Erro ao buscar notificações ==============')
          console.log(err);
        }
      })
    }
  }

  markAsRead(notificationId: number) {
    return this.dataSource.put(`${environment.apiUrl}/Notification/${notificationId}`, {}, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  private getAll(page: number = 0, limit: number = Integer.MAX_VALUE) {
    return this.dataSource.get<SpaNotification[]>(`${environment.apiUrl}/Notification?page=${page}&limit=${limit}`, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}` } }, 1, false);
  }
}
