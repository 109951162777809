import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { FunctionalityService } from 'src/app/core/services/functionality.service';
import { LoginService } from 'src/app/core/services/login.service';
import { MenuService } from 'src/app/core/services/menu.service';
import { LoginResponse } from '../../../shared/model/http/responses/login_response';
import { Menu } from '../../../shared/model/menu.model';
import { User } from '../../../shared/model/user.model';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  authenticatedUser: User;
  menu: Menu = new Menu();

  constructor(private menuService: MenuService, public loginService: LoginService, private functionalityService: FunctionalityService) {
  }

  ngOnInit(): void {
    this.populateMenu();
  }

  private populateMenu() {
    // this.menuService.shouldUpdateMenu.subscribe(value => {
    //   this.getUserDls();
    //   if (value || JSON.parse(localStorage.getItem('session'))) {

        this.menu = this.menuService.getMenuList(null);
    //   }
    // });
  }

  getUserDls(): void {
    const session: LoginResponse = JSON.parse(localStorage.getItem('session'))

    this.authenticatedUser = session?.user;
  }

}
