import { AuxiliaryDbEntity } from "./auxiliary-db-entity.model";
import { Deserializable } from "./deserializable";
import { User } from "./user.model";

export class SpaNotification extends AuxiliaryDbEntity implements Deserializable {
  message?: string;
  read?: boolean;
  readDate?: null;
  aircraftPositioningId?: number;
  dailyCheckId?: null;
  userReadId?: null;
  notificationType?: SpaNotificationType;
  notificationWarning: NotificationWarning;
  aircraftPositioning?: {
    dateScheduled?: string;
    aircraftPrefix?: string;
    criticalityId?: number;
    basesMaintenanceId?: null;
    reasonBaseChangeId?: null;
    reasonCancellationId?: number;
    reasonNotPlacingId?: null;
    adherenceId?: number;
    taskDescription?: string;
    wo?: string;
    userId?: number;
    timeMnt?: string;
    limitationValue?: string;
    flightNumber?: null;
    flightNumberLimitationActual?: null;
    solicitationDate?: null;
    solicitationDateHour?: string;
    positioningDate?: string;
    positioningDateHour?: string;
    positioningCancellationDateHour?: null;
    lastEdition?: string;
    lastEditionUserId?: number;
    descriptionCco?: string;
    descriptionCancellation?: null;
    user?: null;
    lastEditionUser?: null;
    criticality?: null;
    basesMaintenance?: null;
    reasonBaseChange?: null;
    reasonCancellation?: null;
    reasonNotPlacing?: null;
    adherence?: null;
    aircraftPositioningLimitation?: null;
    aircraftPositioningServiceBases?: null;
    notifications?: any[];
    status: number;
    id: number;
    createdBy: number;
    createdAt: string;
    updatedBy: number;
    updatedAt: string;
  };
  dailyCheck?: any;
  user?: User;
  id: number;
  createdBy: number;
  createdAt: string;
  updatedBy: number;
  updatedAt: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  get getScheduleDate(): string {
    try {
      if (this.aircraftPositioning?.dateScheduled) {
        let date = this?.aircraftPositioning?.dateScheduled?.split('T')[0]?.split(' ')[0] ?? '';
        date = date.split('-')?.reverse().join('/');
        return date;
      } else if (this.dailyCheck?.expirationDate) {
        let date = this.dailyCheck?.expirationDate?.split('T')[0]?.split(' ')[0] ?? '';
        date = date.split('-')?.reverse().join('/');
        return date;
      }
    } catch (error) {
      console.log(error);
    }
    return '';
  }

  get getPositioningDate(): string {
    try {
      if (this.aircraftPositioning?.positioningDate) {
        let date = this?.aircraftPositioning?.positioningDate.split('T')[0]?.split(' ')[0] ?? '';
        date = date.split('-')?.reverse().join('/');
        return date;
      } else if (this.dailyCheck?.positioningDate) {
        let date = this.dailyCheck?.positioningDate.split('T')[0]?.split(' ')[0] ?? '';
        date = date.split('-')?.reverse().join('/');
        return date;
      }
    } catch (error) {
      console.log(error);
    }
    return '';
  }

  get getCreatedAtDate(): string {
    try {
      if (this.createdAt) {
        let date = this.createdAt?.split('T')[0]?.split(' ')[0] ?? '';
        date = date.split('-')?.reverse().join('/');
        return date;
      } else if (this.createdAt) {
        let date = this.createdAt?.split('T')[0]?.split(' ')[0] ?? '';
        date = date.split('-')?.reverse().join('/');
        return date;
      }
    } catch (error) {
      console.log(error);
    }
    return '';
  }
}

export enum SpaNotificationType {
  COORDENACAOVOO = 0,
  MANUTENCAO = 1,
}

export enum NotificationWarning {
  LOW = 0,
  MID = 1,
  HIGH = 2,
}
