<div class="data-table" [ngStyle]="{'margin-top': showFilters ? '-15px' : '25px'}">
  <button *ngIf="showSelectAllBtn" mat-raised-button (click)="$event ? masterToggle() : null">
    Selecionar Todos
  </button>
  <div class="d-flex m-25p " [class]="{'hidden-filters': !showFilters}">
    <form class="go-bottom col-3" *ngIf="showSearchButton">
      <div>
        <input (keyup)="applyFilter($event)" id="buscar" #buscar name="buscar" type="text" placeholder="Buscar palavra" />
        <label for="buscar">Buscar palavra</label>
      </div>
    </form>
    <div class="col-3 d-flex" *ngIf="showExportButtons">
      <div class="col">
        <button mat-raised-button (click)="
            exporter.exportTable('xlsx', {
              fileName: exportTablesName,
              Props: { Author: 'Gol' }
            })
          ">
          Excel
        </button>
      </div>
      <div class="col">
        <button mat-raised-button (click)="
            exporter.exportTable('csv', {
              fileName: exportTablesName,
              Props: { Author: 'Gol' }
            })
          ">
          CSV
        </button>
      </div>
      <div class="col">
        <button mat-raised-button (click)="
            exporter.exportTable('json', {
              fileName: exportTablesName,
              Props: { Author: 'Gol' }
            })
          ">
          Json
        </button>
      </div>
      <div class="col">
        <button mat-raised-button (click)="
            exporter.exportTable('txt', {
              fileName: exportTablesName,
              Props: { Author: 'Gol' }
            })
          ">
          txt
        </button>
      </div>
    </div>
    <div class="col-2 add-row" style="display: flex; justify-content: space-between; height: 39px;">
      <button  mat-raised-button color="primary" (click)="addNewRow()" *ngIf="showAddOnRowButton" >
        {{AddRowButtonText}}
      </button>
      <button *ngIf="showUploadDailyCheck" mat-raised-button color="primary" (click)="formUploadDailyCheck()">
        Upload Daily Check
      </button>
    </div>
    <div *ngIf="showExpandTable" class="col-2" style="justify-content: end; display: flex;">
        <button class="expand-button" (click)="hideFilters()" mat-raised-button>
          {{showFilters ? 'Expandir' : 'Recolher'}}
        </button>
    </div>
  </div>

  <div class="mat-elevation-z8 table-container table-responsive {{hasStickyHeader ? 'sticky-table' : ''}}"
  [ngStyle]="{'height': showFilters ? '70vh' : '80vh'}">
    <table mat-table [dataSource]="dataSource" #exporter="matTableExporter" [hiddenColumns]="exportHiddenColumns"
      matTableExporter matSort>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let row; let i = index" [class]="(i % 2 == 0 ? 'even' : 'odd')">
          <mat-checkbox *ngIf="showSelectBox" (click)="$event.stopPropagation()"
            (change)="$event ? onSelectRow(row) : null" [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="column" *ngFor="let column of columnsToDisplay | slice: 1">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 10px;">
          <div>
            <div class="d-flex" [innerHTML]="
            column === 'acoes'
            ? 'AÇÕES'
            : column === 'email'
            ? 'E-MAIL'
            : headerTitle(column)">
          </div>
          <div *ngIf="column == 'Cco'" >
            <mat-checkbox (change)="selectAllCco()" [(ngModel)]="allCcoCheck">
            </mat-checkbox>
          </div>
          <div *ngIf="column == 'Mnt'">
            <mat-checkbox (change)="selectAllMnt()" [(ngModel)]="allMntCheck">
            </mat-checkbox>
          </div>
          </div>
        </th>
        <td (keydown.enter)="editRow(row)" (keyup.escape)="cancelEditing(row)" mat-cell *matCellDef="let row; let i = index; let even = even; let odd = odd" style="padding-right: 10px; text-align: center;"
          [class]="{ red: needHightligth(row, column), 
            'second-hightligth': secondHightligth(row, column), 
            'aderencia-hightlight': row['Aderência'] == 11 && column == 'Aderência',
            'limitationValue-hightlight': row['showLimitationValueAlert'] && column == 'Valor limitação',
            'pending-highlight': pendingHighlight(row),
            'evaluate-cco-highlight': row.avaliarCoordenacao && this.columnsToDisplay.includes('Cco'),
            'evaluate-maintenance-highlight': row.avaliarManutencao && this.columnsToDisplay.includes('Mnt'),
            'hang': row['isEditing'] && row['newRow'],
            even: even, odd: odd }">
          <div *ngIf="row['isEditing'] && column !== 'acoes' && column !== 'active'">
            <div *ngIf="this.selectFieldOptions.hasOwnProperty(column)">
              <ng-select [cdkTrapFocusAutoCapture]="column == 'Prefixo'" [cdkTrapFocus]="true" [placeholder]="getPlaceholder(column)" [ngModel]="row[column]" (change)="inputHandler($event, column, row)"
                [items]="column == 'Motivo' && this.selectFieldOptions[column][row['id']] ? this.selectFieldOptions[column][row['id']] : this.selectFieldOptions[column]"
                [disabled]="(disabledFields && disabledFields.includes(column))"
                bindLabel="name" bindValue="value"></ng-select>
            </div>
            <div *ngIf="this.selectMultipleFieldOptions.hasOwnProperty(column)">
              <ng-select [placeholder]="getPlaceholder(column)" [multiple]="true" (change)="inputHandler($event, column, row)"
                [ngModel]="row[column]" [items]="this.selectMultipleFieldOptions[column]"
                [disabled]="(disabledFields && disabledFields.includes(column))"
                bindLabel="name" bindValue="value"
              ></ng-select>
            </div>
            <mat-form-field *ngIf="!this.selectFieldOptions.hasOwnProperty(column) && !this.selectMultipleFieldOptions.hasOwnProperty(column) && column !== 'Mnt' && column !== 'Cco' && column !== 'Data Vencimento' && column !== 'Data Posicionamento' && column !== 'Data Programação' && column !== 'Data Programação LT' && column !== 'Editado em UTC' && column !== 'Alterado em UTC' && column !== 'Data Solicitação' && column !== 'obs mnt' && column !== 'Data Pos'"
            [ngClass]="column !== 'Bases Atendimento' ? 'input-data-table' : 'input-bases-data-table'">
              <input [placeholder]="getPlaceholder(column)" matInput
                [value]="formatValue(row, column)"
                (change)="inputHandler($event, column, row)"
                [type]="(column == 'Tempo MNT' || column == 'Hora Pos'  || column == 'Hora Pos UTC'  || column == 'Hora Pos LT'  || column == 'Hora Vencimento UTC'  || column == 'Hora Vencimento UTC'  )? 'time' : (column === 'Nível' || column === 'Flight number')? 'number' : (column === 'Data Vencimento' || column === 'Data Posicionamento' || column === 'Data Programação' || column === 'Data Programação LT' || column === 'Editado em UTC' || column === 'Alterado em UTC' || column === 'Data Solicitação' || column === 'Data Pos' ? 'date' : 'text')"
                [disabled]="(disabledFields && disabledFields.includes(column))"
              />
            </mat-form-field>

            <mat-form-field *ngIf="!this.selectFieldOptions.hasOwnProperty(column) && !this.selectMultipleFieldOptions.hasOwnProperty(column) && column !== 'Mnt' && column !== 'Cco' && (column === 'Data Vencimento' || column === 'Data Posicionamento' || column === 'Data Programação' || column === 'Data Programação LT' || column === 'Data Solicitação' || column === 'Editado em UTC' || column === 'Alterado em UTC' || column === 'Data Pos')"
              [ngClass]="column !== 'Bases Atendimento' ? 'input-data-table' : 'input-bases-data-table'">
              <input [placeholder]="getPlaceholder(column)" matInput
                [value]="formatValue(row, column)"
                (change)="inputHandler($event, column, row)"
                [type]="'date'"
                min="{{startDateCalendar | date:'yyyy-MM-dd'}}"
                [disabled]="(disabledFields && disabledFields.includes(column))"
              />
            </mat-form-field>
          </div>
          <div *ngIf="column == 'Cco'" style="display: flex; justify-content: center;">
            <mat-checkbox [(ngModel)]="row.avaliarCoordenacao" (change)="changeCcoCheck(row)">
            </mat-checkbox>
          </div>
          <div *ngIf="column == 'Mnt'" style="display: flex; justify-content: center;">
            <mat-checkbox [(ngModel)]="row.avaliarManutencao" (change)="changeMaintenanceCheck(row)">
            </mat-checkbox>
          </div>
          {{
            column === "active"
              ? null
                : column === "transportType"
                  ? transportType[row[column]]
                    : column === "price"
                      ? (row[column] | currency: "BRL":true)
                      : !(row['isEditing'])
                      ? getRowName(row, column) : null
                    }}
          
          <span *ngIf="column === 'active'">
            <mat-icon class="mat-icon material-icons" color="primary" role="img" *ngIf="row.active">thumb_up</mat-icon>
            <mat-icon class="mat-icon material-icons" role="img" *ngIf="!row.active" style="color: #b60303">thumb_down
            </mat-icon>
          </span>

          <span *ngIf="column === 'obs mnt'">
            <div *ngIf="row.id">
              <div *ngIf="row?.obsMnt == ''; else existObs">
                <mat-icon class="mat-icon material-icons" color="primary" style="cursor: pointer; margin-left: 20px; color: #09b920"
                matTooltip="Não possui observação" matTooltipPosition="above" (click)="onClickObsMnt(row, column)">
                cloud_done
              </mat-icon>
              </div>
              <ng-template #existObs>
                <mat-icon class="mat-icon material-icons" color="primary" style="cursor: pointer; margin-left: 20px; color: #ee6b2e"
                  matTooltip="{{tooltipReturn(row)}}" matTooltipPosition="above" (click)="onClickObsMnt(row, column)">
                  cloud_done
                </mat-icon>
              </ng-template>
            </div>
          </span>
          <span class="mat-button-wrapper" *ngIf="column === 'acoes' && row['isEditing'] && row['showButtons']">
            <mat-icon class="mat-icon material-icons material-icons-outlined material-symbols-outlined" color="primary" style="cursor: pointer; color: #5CB85C"
              (click)="editRow(row)" matTooltip="Aceitar" matTooltipPosition="above">check_circle
            </mat-icon>
            <mat-icon class="mat-icon material-icons" color="primary" style="cursor: pointer; color: #D9534F"
              (click)="cancelEditing(row)" matTooltip="Cancelar" matTooltipPosition="above">close
            </mat-icon>
          </span>
          <span class="mat-button-wrapper" *ngIf="column === 'acoes' && !row['isEditing']" style="display: flex; margin-top: 12px; justify-content: center;">
            <mat-icon class="mat-icon material-icons me-1" color="primary" style="cursor: pointer"
              *ngIf="showCustomButton" (click)="onClick(row, 'custom')" matTooltipPosition="above">
              {{ customActionIcon }}
            </mat-icon>

            <mat-icon class="mat-icon material-icons" color="primary" style="cursor: pointer; color: #3a6305"
              *ngIf="showThreshold" (click)="onClick(row, 'threshold')" matTooltip="cabeceiras" matTooltipPosition="above">edit_road
            </mat-icon>

            <mat-icon class="mat-icon material-icons" color="primary" style="cursor: pointer" *ngIf="showDetalheConexao"
            (click)="onClick(row, 'detalheConexao')" matTooltip="Detalhes conexão" matTooltipPosition="above"> connecting_airports
            </mat-icon>

            <mat-icon class="mat-icon material-icons" color="primary" style="cursor: pointer" *ngIf="showDetalheEmbarque"
            (click)="onClick(row, 'detalheEmbarque')" matTooltip="Detalhes embarque" matTooltipPosition="above">flight_takeoff
            </mat-icon>

            <mat-icon class="mat-icon material-icons" color="global" style="cursor: pointer" *ngIf="showEditButton"
              (click)="onClick(row, 'edit')" matTooltip="Editar" matTooltipPosition="above">edit
            </mat-icon>

            <mat-icon class="mat-icon material-icons" color="global" style="cursor: pointer" *ngIf="showEditOnRowButton"
              (click)="startEdit(row)" matTooltip="Editar linha" matTooltipPosition="above">edit
            </mat-icon>

            <mat-icon class="mat-icon material-icons me-1" color="global" style="cursor: pointer"
              *ngIf="showExportPdfRpe" (click)="onClick(row, 'downloadPdfRpe')" matTooltip="Download PDF Rpe" matTooltipPosition="above">receipt_long
            </mat-icon>

            <mat-icon class="mat-icon material-icons" color="global" style="cursor: pointer" *ngIf="showExportPdfSpa"
              (click)="onClick(row, 'downloadPdfSpa')" matTooltip="Download PDF SPA" matTooltipPosition="above">download_pdf_Spa
            </mat-icon>

            <mat-icon class="mat-icon material-icons" color="primary" style="cursor: pointer" *ngIf="showViewButton"
              (click)="onClick(row, 'view')" matTooltip="Visualizar" matTooltipPosition="above">pageview
            </mat-icon>

            <mat-icon class="mat-icon material-icons" color="warn" style="cursor: pointer" *ngIf="showUpdateButton"
              (click)="onClick(row, 'update-vagas')" matTooltip="Atualizar Vagas" matTooltipPosition="above">hotel
            </mat-icon>

            <mat-icon class="mat-icon material-icons" style="cursor: pointer; color: #b60303" *ngIf="showDeleteButton"
              (click)="onClick(row, 'delete')" matTooltip="Excluir" matTooltipPosition="above">delete_forever
            </mat-icon>

            <mat-icon class="mat-icon material-icons" style="cursor: pointer;" *ngIf="showCopyButton"
              (click)="onClick(row, 'copy')"  matTooltip="Copiar" matTooltipPosition="above">content_copy
            </mat-icon>

            <mat-icon class="mat-icon material-icons" style="cursor: pointer;" *ngIf="showApproveButton && row.approveChanges == 1"
              (click)="approveChanges(row)"  matTooltip="Aprovar" matTooltipPosition="above">check
            </mat-icon>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay" (dblclick)="doubleClicked(row)"></tr>
    </table>
    <mat-paginator *ngIf="showPagination" class="paginator" [length]="dataSource.data.length" [pageSizeOptions]="[ 10, 50, 100, 500]">
    </mat-paginator>
  </div>
</div>
