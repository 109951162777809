import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { InterceptorModule } from './core/helpers/http-interceptor.module';
import { LoginService } from './core/services/login.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { StorageService } from './core/services/storage.service';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { NotificationService } from './shared/services/notification.service';
import { MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import * as msal from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

registerLocaleData(localePt);

export function MSALInstanceFactory(): msal.IPublicClientApplication {
  return new msal.PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      redirectUri: environment.replyUrl,
      authority: 'https://login.microsoftonline.com/60fd783c-fa48-4af7-9416-f06a227cadf6' + '/',
      navigateToLoginRequestUrl: false
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: msal.LogLevel, message: string, containsPii: boolean): void => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
            console.error(message);
            return;
          }
        },
        piiLoggingEnabled: false,
      },
}
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: msal.InteractionType.Popup,
    protectedResourceMap
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    InterceptorModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    SharedModule,
    CoreModule,
    MsalModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }, LoginService, StorageService, NotificationService,
  { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory }, MsalService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory
  }],
  bootstrap: [AppComponent],
})
export class AppModule {}
