import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { LoadingService } from 'src/app/core/services/loading.service';
import { LoginService } from 'src/app/core/services/login.service';
import { SpaNotificationService } from 'src/app/core/services/spa-notification.service';
import { NotificationWarning, SpaNotification } from 'src/app/shared/model/spa-notification.model';
import { AircraftPositioningService } from 'src/app/core/services/aircraft-positioning.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { LoginResponse } from 'src/app/shared/model/http/responses/login_response';
import { SessionService } from '../../services/session.service';
import { User } from 'src/app/shared/model/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  @Output() toggleAction: EventEmitter<boolean> = new EventEmitter();
  openSideNav = true;
  @ViewChild('menuTrigger') trigger: MatMenuTrigger;
  dadosUser: LoginResponse;
  user: User;
  isEditing: boolean;

  constructor(
    private router: Router,
    public loginService: LoginService,
    public loadingService: LoadingService,
    protected spaNotifications: SpaNotificationService,
    protected positioningService: AircraftPositioningService,
    private notification: NotificationService,    
    public sessionService: SessionService,
  ) { }

  ngOnInit(): void {
    this.dadosUser = this.sessionService.get();
    this.user = this.dadosUser?.user;
  }

  toggleSideNav(): void {
    this.openSideNav = !this.openSideNav;
    this.toggleAction.emit(this.openSideNav);
  }

  logout(): void {
    this.loginService.logout();
  }

  markNotificationAsRead(notification: SpaNotification, event?: any) {
    if (event) 
      event.stopPropagation();

    this.spaNotifications.markAsRead(notification.id).subscribe({
      next: (res) => {
        this.spaNotifications.updateNotifications();
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
        if (!event)
          this.sendToPage(notification);
      }
    });
  }

  sendToPage(val: SpaNotification) {
    let currentUrl = this.router.url;

    if (val.aircraftPositioningId) {
      if (val.aircraftPositioning.status == 0) {
        if (currentUrl == '/posicionamento/listar') {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(currentUrl, { state: { notificationItem: { id: val.aircraftPositioning.id, prefixo: val.aircraftPositioning.aircraftPrefix, showEdit: this.isEditing } } });
            this.isEditing = false;
          });
        } else {
          this.router.navigateByUrl('/posicionamento/listar', { state: { notificationItem: { id: val.aircraftPositioning.id, prefixo: val.aircraftPositioning.aircraftPrefix, showEdit: this.isEditing } } });
          this.isEditing = false;
        }
      } else if (val.aircraftPositioning.status == 1) {
        if (currentUrl == '/posicionamento-concluido/listar') {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(currentUrl, { state: { notificationItem: { id: val.aircraftPositioning.id, prefixo: val.aircraftPositioning.aircraftPrefix, showEdit: this.isEditing } } });
            this.isEditing = false;
          });
        } else {
          this.router.navigateByUrl('/posicionamento-concluido/listar', { state: { notificationItem: { id: val.aircraftPositioning.id, prefixo: val.aircraftPositioning.aircraftPrefix, showEdit: this.isEditing } } });
          this.isEditing = false;
        }
      }
    } else if (val.dailyCheckId) {
      if (val.dailyCheck.status == 0) {
        if (currentUrl == '/daily-check/listar') {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(currentUrl, { state: { notificationItem: { id: val.dailyCheckId, prefixo: val.dailyCheck.aircraftPrefix  } } });
          });
        } else {
          this.router.navigateByUrl('/daily-check/listar', { state: { notificationItem: { id: val.dailyCheckId, prefixo: val.dailyCheck.aircraftPrefix  } } });
        }
      } else if (val.dailyCheck.status == 1) {
        if (currentUrl == '/daily-check-concluido/listar') {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(currentUrl, { state: { notificationItem: { id: val.dailyCheckId, prefixo: val.dailyCheck.aircraftPrefix  } } });
          });
        } else {
          this.router.navigateByUrl('/daily-check-concluido/listar', { state: { notificationItem: { id: val.dailyCheckId, prefixo: val.dailyCheck.aircraftPrefix  } } });
        }
      }
    }
  }

  reschedulePositioning(notification: SpaNotification, isEditing: boolean = false) {
    this.isEditing = isEditing;
    if (notification.aircraftPositioningId) {
      this.positioningService.reschedule(notification.aircraftPositioningId).subscribe({
        next: (res: any) => {
            notification.aircraftPositioning = res;
            this.markNotificationAsRead(notification);
        },
        error: (err) => {
          if (err.error.includes("Você não possui permissão")) {
            this.notification.error(err.error);
          } else if (err.error.includes("Posicionamento com este prefixo")) {
            this.notification.error(err.error);
          } else if (err.error.includes("Já existe um posicionamento cadastrado para o prefixo")) {
            this.notification.error(err.error);
          } else if (err.error.includes("Não foi possível localizar uma")) {
            this.notification.error('Erro ao realizar reagendamento. Verifique o posicionamento da aeronave no Netline.');
          } else {
            this.notification.error('Erro ao realizar reagendamento. Procure o administrador do sistema!');
          }
        }
      });
    }
  }

  getNotificationWarning(notification: SpaNotification): string {
    if (notification.notificationWarning == NotificationWarning.HIGH) {
      return 'redAlert';
    }
    else if (notification.notificationWarning == NotificationWarning.MID) {
      return 'yellowAlert';
    }
    else {
      return 'greenAlert';
    }
  }

}
