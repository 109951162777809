
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpDataSource } from '../data_source/config';
import { Integer } from '../helpers/integer';
import { Functionality } from '../../shared/model/functionality.model';
import { PermissionRequest } from '../../shared/model/http/requests/permission-request';
import { PermissionResponse } from '../../shared/model/http/responses/permission-response';
import { Permission } from '../../shared/model/permission.model';
import { SessionService } from './session.service';

@Injectable({ providedIn: 'root' })
export class FunctionalityService {

  constructor(
    private dataSource: HttpDataSource,
    private sessionService: SessionService
  ) {
  }

  addPermission(Functionality: PermissionRequest): Observable<Permission> {
    return this.dataSource.post<Permission>(`${environment.apiUrl}/Functionality/Permission`, Functionality, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  updatePermission(Functionality: PermissionRequest): Observable<void> {
    return this.dataSource.put<void>(`${environment.apiUrl}/Functionality/Permission`, Functionality, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  delete(id: any): Observable<void> {
    return this.dataSource.delete<void>(`${environment.apiUrl}/Functionality/Permission/${id}`, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  getAll(page: number = 0, limit: number = Integer.MAX_VALUE) {
    return this.dataSource.get<Functionality[]>(`${environment.apiUrl}/Functionality?page=${page}&limit=${limit}`, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  getLastFifth(page: number = 0, limit: number = Integer.MAX_VALUE) {
    return this.dataSource.get<PermissionRequest[]>(`${environment.apiUrl}/Functionality/Permission?page=${page}&limit=${limit}`, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  getById(ProfileId: number) {
    return this.dataSource.get<PermissionResponse>(`${environment.apiUrl}/Functionality/Permission/${ProfileId}`, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  getByProfileId(ProfileId: number) {
    return this.dataSource.get<Permission>(`${environment.apiUrl}/Functionality/PermissionProfile/${ProfileId}`, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  getPermissions(page: number = 0, limit: number = Integer.MAX_VALUE) {
    return this.dataSource.get<Permission[]>(`${environment.apiUrl}/Functionality/Permission?page=${page}&limit=${limit}`, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }
}
