import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe, formatDate } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { ModalConfirmacaoComponent } from '../modal-confirmacao/modal-confirmacao.component';
import { ConfiguracoesService } from 'src/app/core/services/configuracoes.service';
import { filter } from 'rxjs';
import { SessionService } from 'src/app/core/services/session.service';
import { StorageService } from 'src/app/core/services/storage.service';

export enum TransportType {
  Ônibus,
  Taxi,
  Outros,
}

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() columnsToDisplay: string[] = [];
  @Input() data: any[] = [];
  @Input() isLoadingResults = false;
  @Input() noDataMessage = 'Nenhum dado encontrado.';
  @Input() paginationMessage = 'Mostrando:';
  @Input() hasStickyHeader = false;
  @Input() showSearchButton = true;
  @Input() showExportButtons = true;
  @Input() showExportPdfSpa = false;
  @Input() showExportPdfRpe = false;
  @Input() showSearch = true;
  @Input() showThreshold = false;
  @Input() showSelectBox = false;
  @Input() showEditButton = false;
  @Input() showAddOnRowButton = false;
  @Input() showEditOnRowButton = false;
  @Input() hasUpdateHighlight = false;
  @Input() selectFieldOptions: {} = {};
  @Input() requiredFields: string[];
  @Input() disabledFields: string[];
  @Input() selectMultipleFieldOptions: {} = {};
  @Input() showViewButton = false;
  @Input() showValidPboIbo = false;
  @Input() showDetalheEmbarque = false;
  @Input() showDetalheConexao = false;
  @Input() showDeleteButton = false;
  @Input() deleteModalMessage = 'Deseja realmente excluir esse item?';
  @Input() showCopyButton = false;
  @Input() showUpdateButton = false;
  @Input() showCustomButton = false;
  @Input() showSelectAllBtn = false;
  @Input() showPagination = true;
  @Input() AddRowButtonText = 'Adicionar';
  @Input() showApproveButton = false;
  @Input() customActionIcon = 'eye';
  @Input() showUploadDailyCheck = false;
  @Input() showExpandTable = false;

  @Output() selectRowAction = new EventEmitter();
  @Output() deleteAction = new EventEmitter();
  @Output() copyAction = new EventEmitter();
  @Output() validPboIboAction = new EventEmitter();
  @Output() detalheEmbarque = new EventEmitter();
  @Output() detalheConexao = new EventEmitter();
  @Output() thresholdAction = new EventEmitter();
  @Output() editAction = new EventEmitter();
  @Output() startEdition = new EventEmitter();
  @Output() editOnRow = new EventEmitter();
  @Output() changeInputOnRow = new EventEmitter();
  @Output() cancelEditingOnRow = new EventEmitter();
  @Output() copyOnRow = new EventEmitter();
  @Output() viewAction = new EventEmitter();
  @Output() updateAction = new EventEmitter();
  @Output() downloadPdfSpaAction = new EventEmitter();
  @Output() downloadPdfRpeAction = new EventEmitter();
  @Output() customAction = new EventEmitter();
  @Output() conciliationAction = new EventEmitter();
  @Output() approveRow = new EventEmitter();
  @Output() changeCcoCheckAction = new EventEmitter();
  @Output() changeMaintenanceCheckAction = new EventEmitter();
  @Output() obsMntAction = new EventEmitter();
  @Output() expandTable = new EventEmitter();
  @Output() uploadDailyCheck = new EventEmitter();
  @Output() showReload = new EventEmitter();

  public editingRows = [];
  public formGroups: FormGroup[];
  private selection = new SelectionModel<any>(true, []);
  private paginator: MatPaginator;
  private sort: MatSort;
  protected isFiltering = false;
  protected filterColumns = [];
  private dataSolicitation: string = new Date().toUTCString();
  private horaSolicitation: string = new Date().toUTCString();

  dataSource: MatTableDataSource<any>;
  profile: any;
  lastEditedPrefix = '';
  allCcoCheck: boolean = false;
  allMntCheck: boolean = false;
  startDateCalendar: string = this.convertToISODate(this.datePipe.transform(new Date(), 'dd/MM/yyyy'));
  showFilters: boolean = true;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  @ViewChild('TABLE', { static: true }) table: MatTableDataSource<any>;
  @ViewChild('buscar') buscarInput;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    protected configuracoesService: ConfiguracoesService,
    private modal: MatDialog,
    private notification: NotificationService,
    private datePipe: DatePipe,
    private sessionService: SessionService,
  ) { }

  ngOnInit(): void {
    this.profile = this.sessionService.get();
    this.dataSource = new MatTableDataSource(this.data);
    this.cdr.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => this.sortingDateAccessor(item, property, this);
    this.dataSource.filterPredicate = (data, filter) => this.filterPredicate(data, filter, this.columnsToDisplay, this.filterColumns);
    this.dataSource.sort = this.sort;
    this.dataTimeServer();
  }

  dataTimeServer(): void {
    this.configuracoesService.getDateTimeServer()
      .subscribe({
        next: (res) => {
          this.dataSolicitation = res;
          this.horaSolicitation = res;
        },
        error: (err) => {
          this.dataSolicitation = new Date().toUTCString();
          this.horaSolicitation = new Date().toUTCString();
        }
      });
  }

  onClickObsMnt(element, action): void {
    this.obsMntAction.emit({ row: element, column: action });
  }

  selectFilterColumn(column, remove = false) {
    this.filterColumns ??= [];
    let index = this.filterColumns.findIndex(e => e == column);
    if (remove || index >= 0) {
      this.filterColumns.splice(index, 1);
    } else {
      this.filterColumns.push(column)
    }
    this.dataSource.filter = this.dataSource.filter;
  }

  headerTitle(column): string {
    let str = column
      .replace(/([A-Z]+)/g, ' $1')
      .replace(/([A-Z][a-z])/g, ' $1')
      .toUpperCase();
    if (this.requiredFields
      && this.requiredFields.includes(column)
      && this.editingRows.length > 0) {
      str += '<span class="text-danger ps-1"> *</span>';
    }
    return str;
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(): void {
    let searchInput = '';
    let data = [];
    if (this.editingRows.filter(e => e['id'] == null).length > 0) {
      this.editingRows.filter(e => e['id'] == null).forEach(a => {
        let row = { ...{ 'isEditing': true, 'newRow': true }, ...a };
        row['newRow'] = true;
        row['isEditing'] = true;
        data.push(row);
      });
    }
    this.data.forEach((a) => {
      let editingRowsIndex = this.editingRows.findIndex(e => e['id'] == a['id']);
      let row = a;

      if (editingRowsIndex >= 0) {
        row = { ...{ 'isEditing': true }, ...this.editingRows[editingRowsIndex] };
        row['isEditing'] = true;
      } else if (a['notification']) {
        searchInput = row['Prefixo'];
        if (a['shouldEdit']) {
          this.showEditOnRowButton = true;
          this.doubleClicked(row);
        }
      }
      else
        row['isEditing'] = false;

      data.push(row);
    });

    if (this.lastEditedPrefix !== '')
      searchInput = this.lastEditedPrefix;

    this.allCcoCheck = this.data.every(item => item.avaliarCoordenacao);
    this.allMntCheck = this.data.every(item => item.avaliarManutencao);
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;

    if (searchInput !== '')
      this.buscarInput.nativeElement.value = searchInput;

    this.applyFilter(this.buscarInput as HTMLInputElement);
  }

  applyFilter(event: any): void {

    if (this.editingRows.length > 0) {
      this.editingRows.forEach(element => {
        this.dataSource.data = this.dataSource.data.map(item => {
          if (item['id'] === element['id']) {
            item = element;
            item['isEditing'] = true;
          }
          return item;
        });
      });
    }

    let filterValue = event?.nativeElement?.value ? event?.nativeElement?.value : event?.target?.value;
    filterValue = filterValue ? filterValue : '';

    if (filterValue != '' && filterValue) {
      this.lastEditedPrefix = filterValue;
      this.isFiltering = true;
    } else {
      this.lastEditedPrefix = '';
      this.isFiltering = false;
    }

    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onClick(element, action): void {
    switch (action) {
      case 'view':
        this.viewAction.emit(element);
        break;
      case 'delete':
        this.onClickDeleteRow(element)
        break;
      case 'copy':
        this.onClickCopyRow(element)
        break;
      case 'edit':
        this.editAction.emit(element);
        break;
      case 'valid':
        this.validPboIboAction.emit(element);
        break;
      case 'threshold':
        this.thresholdAction.emit(element);
        break;
      case 'update-vagas':
        this.updateAction.emit(element);
        break;
      case 'custom':
        this.customAction.emit(element);
        break;
      default:
        this.viewAction.emit(element);
        break;
      case 'downloadPdfSpa':
        this.downloadPdfSpaAction.emit(element);
        break;
      case 'downloadPdfRpe':
        this.downloadPdfRpeAction.emit(element);
        break;
      case 'detalheEmbarque':
        this.detalheEmbarque.emit(element);
        break;
      case 'detalheConexao':
        this.detalheConexao.emit(element);
        break;
    }
  }

  onClickConciliation(element, action): void {
    this.conciliationAction.emit({ row: element, column: action });
  }

  onSelectRow(row?): void {
    if (row) {
      this.selection.toggle(row);
    }

    if (this.selection.selected.length == 1) {
      this.selectRowAction.emit(row ?? this.selection.selected[0]);
    } else if (this.selection.selected.length > 0) {
      this.selectRowAction.emit(this.selection.selected);
    }
  }

  async onClickDeleteRow(element) {
    const dialogRef = this.modal.open(ModalConfirmacaoComponent, {
      width: '30rem',
      data: { titulo: 'Confirmação', pergunta: this.deleteModalMessage }
    });

    await dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAction.emit(element);
      }
    })
  }

  async onClickCopyRow(element) {
    this.copyAction.emit(element);
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => this.sortingDateAccessor(item, property, this);
    this.dataSource.filterPredicate = (data, filter) => this.filterPredicate(data, filter, this.columnsToDisplay, this.filterColumns);
  }

  sortingDateAccessor(item, property: string, self: any) {
    if (property.toLowerCase().includes("data")) {
      if (item[property]?.includes("/")) {
        if (item[property].length > 10) {
          return item[property].substr(0, 10).split('/').reverse().join('-') + item[property].substr(10);
        }
        return item[property].split('/').reverse().join('-');
      }
      return self.getRowName(item, property);
    }
    else if (property.toLowerCase() === 'alterado em utc' || property.toLowerCase() === 'editado em utc') {
      if (item[property]?.includes("/")) {
        if (item[property].length > 10) {
          return item[property].substr(0, 10).split('/').reverse().join('-') + item[property].substr(10);
        }
        return item[property].split('/').reverse().join('-');
      }

      return self.getRowName(item, property);
    }
    return self.getRowName(item, property);
  }

  filterPredicate(data: Element, filter: string, columnsToDisplay, filteredColumns) {
    let show = false;
    const filters = filter.split(';');
    if (filteredColumns.length == 0) {
      for (let i = 0; i < filters.length; i++) {
        const needle = filters[i];
        for (let index = 0; index < columnsToDisplay.length; index++) {
          const column = columnsToDisplay[index];
          const cell = this.getRowName(data, column) ?? '';
          show = cell && cell.toString().toLowerCase().includes(needle.toLowerCase());
          if (show) {
            break;
          }
        }
        if (show) {
          break;
        }
      }
    } else {
      for (let i = 0; i < filters.length; i++) {
        const needle = filters[i];
        for (let index = 0; index < filteredColumns.length; index++) {
          const column = filteredColumns[index];
          const cell = this.getRowName(data, column) ?? '';
          show = cell && cell.toString().toLowerCase().includes(needle.toLowerCase());
          if (show) {
            break;
          }
        }
        if (show) {
          break;
        }
      }
    }

    return show;
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource ? this.dataSource.data.length : 0;
    return numSelected === numRows;
  }

  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row) => this.selection.select(row));
      this.selection.isSelected(this.dataSource.data[0]);
    }
    this.onSelectRow();
  }

  doubleClicked(element) {
    if (!element.isEditing) {
      if (this.showEditOnRowButton) {
        this.startEdit(element, false, true);
      }
    }
  }

  startEdit(element, newRow = false, copyValue = false) {
    if ((element['id'] && copyValue) || (newRow && !copyValue) || (element['id'] && !element['isEditing'])) {
      element['newRow'] = element['id'] ? false : true;

      if (!element['newRow'])
        this.startEdition.emit(element);

      element['showButtons'] = true;
      element['newRowId'] = this.editingRows.length;
      let editingRow = Object.assign({}, element);
      editingRow['newRow'] = newRow;
      this.editingRows.push(editingRow);
      element['isEditing'] = true;
    }
  }

  inputHandler(element, column, row) {
    let index;
    if (row['newRow']) {
      index = this.editingRows.findIndex(e => e['newRowId'] == row['newRowId']);
    } else {
      index = this.editingRows.findIndex(e => e['id'] == row['id']);
    }
    if (index >= 0) {
      let editingRow = this.editingRows[index];
      if (this.selectMultipleFieldOptions.hasOwnProperty(column) || this.selectFieldOptions.hasOwnProperty(column)) {
        if (!element || typeof element != 'object') {
          editingRow[column] = element
        } else if (element.hasOwnProperty('value')) {
          editingRow[column] = element['value'];
        } else if (Array.isArray(element)) {
          editingRow[column] = element.map(e => e.value);
        } else if (element.hasOwnProperty('option') && element['option']['value']) {
          editingRow[column] = element['option']['value'];
        } else {
          editingRow[column] = element.target.value;
        }
      } else {
        editingRow[column] = element.target.value;
      }
      if (column == 'Aderência' && editingRow.hasOwnProperty('Motivo')) {
        editingRow['Motivo'] = null;
        row['Motivo'] = null;
      }
      this.editingRows[index] = editingRow;
      this.changeInputOnRow.emit({ row, column, value: editingRow[column] });
    }
  }

  convertToISODate(dateStr) {
    var parts = dateStr.split('/');
    var day = parts[0];
    var month = parts[1];
    var year = parts[2];
    return year + '-' + month + '-' + day;
  }

  async editRow(element) {
    let index;
    if (element['newRow']) {
      index = this.editingRows.findIndex(e => e['newRowId'] == element['newRowId']);
    } else {
      index = this.editingRows.findIndex(e => e['id'] == element['id']);
    }
    if (index >= 0) {
      let editingRow = this.editingRows[index];
      var criticality = this.selectFieldOptions["Criticidade"]?.find(e => e.name.toUpperCase() == 'TRÂNSITO');
      var existsPositioning = false;

      if (editingRow['Data Programação LT'] !== undefined && editingRow['Criticidade'] !== criticality.value) {
        existsPositioning = this.dataSource.data.find(e =>
          e['Data Programação LT'] == this.convertToISODate(editingRow['Data Programação LT'])
          && e['Prefixo'] == editingRow['Prefixo']);
      }

      if (existsPositioning) {
        this.notification.error(`Posicionamento com este prefixo e data de posicionamento já cadastrado!`);
        this.dataSource.data.splice(index, 1);
      } else {
        if (this.requiredFields && this.requiredFields.length > 0) {
          let unfilled = [];
          this.requiredFields.forEach(field => {
            if (editingRow[field] == undefined || editingRow[field] == null || editingRow[field] == '') {
              unfilled.push(field);
            }
          });

          if (unfilled.length > 0) {
            if (unfilled.length > 1) {
              this.notification.error(`Preencha os seguintes campos para salvar: ${unfilled.join(', ')}`);
            } else {
              this.notification.error(`Preencha o seguinte campo para salvar: ${unfilled[0]}`);
            }
            return;
          }
        }

        if (editingRow.hasOwnProperty('lastEditColumns')) {
          if (editingRow['id'] && editingRow['id'] != '') {
            let currentRow = this.data.find(e => e.id == editingRow['id']);
            if (currentRow) {
              editingRow['lastEditColumns'] = [];
              this.columnsToDisplay.forEach(e => {
                if (editingRow[e] !== currentRow[e]) {
                  (editingRow['lastEditColumns'] as string[]).push(e);
                }
              });
            } else {
              editingRow['lastEditColumns'] = [];
            }
          } else {
            editingRow['lastEditColumns'] = [];
          }
          editingRow['lastEditColumns'] = (editingRow['lastEditColumns'] as string[]).join('|');
        }
        this.editingRows.splice(index, 1);
        await this.editOnRow.emit({ ...editingRow, ...{ 'newRow': element['newRow'] }, reload: this.editingRows.length == 0 });
        delete editingRow['newRow'];
        element['showButtons'] = false;
        element = editingRow;
      }
    }
    else {
      element['newRow'] = false;
      this.startEdition.emit(element);

      let editingRow = Object.assign({}, element);
      editingRow['newRow'] = false;
      this.editingRows.push(editingRow);
      element['isEditing'] = true;
    }
  }

  cancelEditing(element) {
    element['isEditing'] = false;
    let index = this.editingRows.findIndex(e => e['id'] == element['id']);
    if (index >= 0) {
      this.editingRows.splice(index, 1);
      this.ngOnChanges();
    }
    this.cancelEditingOnRow.emit(element);
  }

  addNewRow() {
    this.dataSource.filter = '';
    if (this.buscarInput)
      this.buscarInput.nativeElement.value = '';

    let newRow = {}
    if (this.dataSource.data.length > 0) {
      let firstElement = this.dataSource.data[0];
      for (let key in firstElement) {
        if (typeof firstElement[key] == 'string') {
          newRow[key] = '';
          if (key == 'Data Solicitação' || key == 'solicitationDate')
            newRow[key] = this.datePipe.transform(this.dataSolicitation, 'dd/MM/yyyy');
          if (key == 'Hora Solicitação' || key == 'solicitationDateHour')
            newRow[key] = this.datePipe.transform(this.horaSolicitation, 'HH:mm', "UTC");
          if (key == 'Hora Solicitação LT' || key == 'solicitationDateHour')
            newRow[key] = this.datePipe.transform(this.horaSolicitation, 'HH:mm', "UTC-3");
          if (key == 'Hora Solicitação UTC' || key == 'solicitationDateHour')
            newRow[key] = this.datePipe.transform(this.horaSolicitation, 'HH:mm', "UTC");
        } else {
          newRow[key] = null;
        }
      }
    } else {
      this.columnsToDisplay.forEach(headerCell => {
        newRow[headerCell] = '';
        if (headerCell == 'Data Solicitação' || headerCell == 'solicitationDate')
          newRow[headerCell] = this.datePipe.transform(this.dataSolicitation, 'dd/MM/yyyy');
        if (headerCell == 'Hora Solicitação' || headerCell == 'solicitationDateHour')
          newRow[headerCell] = this.datePipe.transform(this.horaSolicitation, 'HH:mm');
        if (headerCell == 'Hora Solicitação LT' || headerCell == 'solicitationDateHour')
          newRow[headerCell] = this.datePipe.transform(this.horaSolicitation, 'HH:mm', "UTC-3");
        if (headerCell == 'Hora Solicitação UTC' || headerCell == 'solicitationDateHour')
          newRow[headerCell] = this.datePipe.transform(this.horaSolicitation, 'HH:mm');
      });
    }
    this.dataSource.data = [newRow, ...this.dataSource.data];
    this.startEdit(newRow, true);
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1
      }`;
  }

  get exportHiddenColumns(): number[] {
    let hidden = [];
    if (this.columnsToDisplay.includes('select')) {
      hidden.push(this.columnsToDisplay.indexOf('select'));
    }
    if (this.columnsToDisplay.includes('reproc')) {
      hidden.push(this.columnsToDisplay.indexOf('reproc'));
    }
    if (this.columnsToDisplay.includes('acoes')) {
      hidden.push(this.columnsToDisplay.indexOf('acoes'));
    }
    return hidden;
  }

  get exportTablesName(): string {
    let name = this.router.url.split('/').join('-');
    if (name.startsWith('-')) {
      name = name.substring(1);
    }
    return name;
  }

  needHightligth(row, column) {
    if (row.hasOwnProperty('lastEditColumns') && this.hasUpdateHighlight) {
      if (column !== 'acoes' && column !== 'active') {
        let lastEditColumns: string[] = (row['lastEditColumns'] ?? '')
          .split('|').map(e => e.toUpperCase());
        if (this.dataSource.data && lastEditColumns.includes(column.toUpperCase())) {
          return true;
        }
      }
    }
    return false;
  }

  secondHightligth(row, column) {
    if (column == 'Limitação'
      && row.hasOwnProperty('Data Programação LT') && row.hasOwnProperty('Limitação')
      && this.selectMultipleFieldOptions.hasOwnProperty('Limitação')
      && this.hasUpdateHighlight) {
      try {
        // Quando ajustar a data, mudar aqui:
        let today = formatDate(new Date(), 'dd/MM/yyyy', 'pt-BR').split('/');
        let programDate = (row['Data Programação LT'] ?? '').split('/');

        const isDataAtual = programDate.length >= 3
          && today[0] == programDate[0]
          && today[1] == programDate[1]
          && today[2] == programDate[2];

        let limitations = this.selectMultipleFieldOptions['Limitação'].filter(e => e.name == "20:59"
          || e.name == "23:59" || e.name == "FC+23:59" || e.name == "FH+23:59");

        let hasTheLimitation = false;
        if (row['Limitação'] !== "") {
          (row['Limitação'] ?? []).forEach(l => {
            if (limitations.findIndex(e => e.value == l) >= 0) {
              hasTheLimitation = true;
            }
          });
        }
        return isDataAtual && hasTheLimitation;
      } catch (error) {
        return false;
      }
    }

    return false;
  }

  getRowName(row: any, column: string): string {
    if (column == 'Cco' || column == 'Mnt')
      return null;

    if (this.selectFieldOptions.hasOwnProperty(column)) {

      let columnName;

      if (column == 'Motivo') {
        let validMotivo = this.selectFieldOptions["Aderência"].find(e => e.value == row["Aderência"]);

        if (validMotivo?.value == 3) {
          // CANC
          columnName = 'TodosMotivosCanc';
        } else if (validMotivo?.value == 4) {
          // NÃO POS
          columnName = 'TodosMotivosNaoPos';
        } else if (validMotivo?.value == 2) {
          // ALT BASE
          if (row.hasOwnProperty("Check")) {
            columnName = column;
          } else {
            columnName = 'TodosMotivosAlt';
          }
        } else {
          columnName = column;
        }

      } else {
        columnName = column;
      }

      let option;
      if (columnName == 'Motivo' && this.selectFieldOptions[columnName].length > 0 && this.selectFieldOptions[columnName][row['id']]) {
        option = this.selectFieldOptions[columnName][row['id']].find(e => e.value == row[column]);
      } else {
        option = this.selectFieldOptions[columnName].find(e => e?.value == row[column]);
      }
      return option ? option.name : row[column];
    } else if (this.selectMultipleFieldOptions.hasOwnProperty(column)) {
      let values;
      if (!Array.isArray(row[column])) {
        values = row[column];
      } else {
        values = row[column].toString().split(',');
      }
      let options = [];
      if (typeof values == 'string') {
        values = [values];
      }

      let columnName;

      if (column == 'Motivo') {

        let validMotivo = this.selectFieldOptions["Aderência"].find(e => e.value == row["Aderência"]);

        if (validMotivo?.value == 3) {
          // CANC
          columnName = 'TodosMotivosCanc';
        } else if (validMotivo?.value == 4) {
          // NÃO POS
          columnName = 'TodosMotivosNaoPos';
        } else if (validMotivo?.value == 2) {
          // ALT BASE
          if (row.hasOwnProperty("Check")) {
            columnName = column;
          } else {
            columnName = 'TodosMotivosAlt';
          }
        } else {
          columnName = column;
        }

      } else {
        columnName = column;
      }

      values?.forEach(item => {
        let selectedValue = this.selectMultipleFieldOptions[columnName].find(e => e.value == item);
        options.push(selectedValue ? selectedValue.name : item);
      });
      if (options.length > 0) {
        return options.join(', ');
      } else {
        return row[column];
      }
    } else {
      return row[column];
    }
  }

  filteredSelectOptions(row: any, column: string) {
    let index = this.editingRows.findIndex(e => e['id'] == row['id']);
    if (index >= 0) {
      let editingRow = this.editingRows[index];
      let value = (editingRow[column] ?? '').toString();
      return this.selectFieldOptions[column].filter(e => e.name.toLowerCase().includes(value.toLowerCase()));
    }
  }

  filteredSelectMultipleOptions(row: any, column: string) {
    let index = this.editingRows.findIndex(e => e['id'] == row['id']);
    if (index >= 0) {
      let editingRow = this.editingRows[index];
      let value = (editingRow[column] ?? '').toString();
      return this.selectMultipleFieldOptions[column].filter(e => e.name.toLowerCase().includes(value.toLowerCase()));
    }
  }

  formatValue(row, column) {
    if (column === 'Data Programação' || column === 'Data Programação LT' || column === 'Data Solicitação' || column === 'Data Pos' || column === 'Data Vencimento' || column === 'Data Posicionamento' || column === 'Editado em UTC' || column === 'Alterado em UTC') {
      if (row[column]?.split('/').length == 3) {
        let date = row[column]?.split(' ')[0].split('/').reverse()?.join('-');
        if (row[column]?.split(' ').length > 1) {
          date += ' ' + row[column]?.split(' ')[1];
        }
        return date;
      }
      return row[column];
    }
    return row[column]
  }

  formatSelectValue(row, column) {
    if (this.selectFieldOptions.hasOwnProperty(column)) {
      if (this.selectFieldOptions[column].find((e) => e.value == row[column])) {
        return row[column];
      } else {
        return null;
      }
    }
    return row[column];
  }

  approveChanges(row) {
    this.approveRow.emit(row.id);
  }

  pendingHighlight(row) {
    return row["approveChanges"] == 1;
  }

  getPlaceholder(itemName: string): string {
    return itemName;
  }

  changeCcoCheck(row: any) {
    let idList: number[] = [row.id];
    this.changeCcoCheckAction.emit(idList);
  }

  changeMaintenanceCheck(row: any) {
    let idList: number[] = [row.id];
    this.changeMaintenanceCheckAction.emit(idList);
  }

  selectAllCco() {
    let idList;
    if (!this.allCcoCheck)
      idList = this.data.map(item => item.id);
    else
      idList = this.data.reduce(function (acc, cur) {
        if (!cur.avaliarCoordenacao) {
          acc.push(cur.id);
        }
        return acc;
      }, []);
    this.changeCcoCheckAction.emit(idList);
  }

  selectAllMnt() {
    let idList;
    if (!this.allMntCheck)
      idList = this.data.map(item => item.id);
    else
      idList = this.data.reduce(function (acc, cur) {
        if (!cur.avaliarManutencao) {
          acc.push(cur.id);
        }
        return acc;
      }, []);
    this.changeMaintenanceCheckAction.emit(idList);
  }

  tooltipReturn(row) {
    return row.obsMnt;
  }

  hideFilters() {
    this.showFilters = !this.showFilters;
    this.expandTable.emit(this.showFilters)
  }

  formUploadDailyCheck() {
    this.uploadDailyCheck.emit();
  }
}
