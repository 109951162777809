export class ExternalLoginProviderInfoModel implements IExternalLoginProviderInfoModel {
    name: string;
    clientId: string;

    constructor(data?: IExternalLoginProviderInfoModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(data?: any) {
        if (data) {
            this.name = data["name"];
            this.clientId = data["clientId"];
        }
    }

    static fromJS(data: any): ExternalLoginProviderInfoModel {
        let result = new ExternalLoginProviderInfoModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["name"] = this.name;
        data["clientId"] = this.clientId;
        return data;
    }
}

export interface IExternalLoginProviderInfoModel {
    name: string;
    clientId: string;
}

export class ExternalLoginProvider extends ExternalLoginProviderInfoModel {


    static readonly MICROSOFT: string = 'Microsoft';
  
    icon: string;
    initialized = false;
  
    constructor(providerInfo: ExternalLoginProviderInfoModel) {
        super();
  
        this.name = providerInfo.name;
        this.clientId = providerInfo.clientId;
        this.icon = ExternalLoginProvider.getSocialIcon(this.name);
    }
  
    private static getSocialIcon(providerName: string): string {
        providerName = providerName.toLowerCase();
  
        if (providerName === 'google') {
            providerName = 'googleplus';
        }
  
        return providerName;
    }
  }
  
  export class ExternalAuthenticateResultModel implements IExternalAuthenticateResultModel {
    accessToken: string;
    encryptedAccessToken: string;
    expireInSeconds: number;
    waitingForActivation: boolean;
    returnUrl: string;
  
    constructor(data?: IExternalAuthenticateResultModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
  
    init(data?: any) {
        if (data) {
            this.accessToken = data["accessToken"];
            this.encryptedAccessToken = data["encryptedAccessToken"];
            this.expireInSeconds = data["expireInSeconds"];
            this.waitingForActivation = data["waitingForActivation"];
            this.returnUrl = data["returnUrl"];
        }
    }
  
    static fromJS(data: any): ExternalAuthenticateResultModel {
        let result = new ExternalAuthenticateResultModel();
        result.init(data);
        return result;
    }
  
    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["accessToken"] = this.accessToken;
        data["encryptedAccessToken"] = this.encryptedAccessToken;
        data["expireInSeconds"] = this.expireInSeconds;
        data["waitingForActivation"] = this.waitingForActivation;
        data["returnUrl"] = this.returnUrl;
        return data;
    }
  }
  
  export interface IExternalAuthenticateResultModel {
    accessToken: string;
    encryptedAccessToken: string;
    expireInSeconds: number;
    waitingForActivation: boolean;
    returnUrl: string;
  }
  
  export interface IExternalAuthenticateModel {
    authProvider: string;
    providerKey: string;
    providerAccessCode: string;
    returnUrl: string;
    singleSignIn: boolean;
  }
  export class ExternalAuthenticateModel implements IExternalAuthenticateModel {
    authProvider: string;
    providerKey: string;
    providerAccessCode: string;
    returnUrl: string;
    singleSignIn: boolean;
  
    constructor(data?: IExternalAuthenticateModel) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
  
    init(data?: any) {
        if (data) {
            this.authProvider = data["authProvider"];
            this.providerKey = data["providerKey"];
            this.providerAccessCode = data["providerAccessCode"];
            this.returnUrl = data["returnUrl"];
            this.singleSignIn = data["singleSignIn"];
        }
    }
  
    static fromJS(data: any): ExternalAuthenticateModel {
        let result = new ExternalAuthenticateModel();
        result.init(data);
        return result;
    }
  
    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["authProvider"] = this.authProvider;
        data["providerKey"] = this.providerKey;
        data["providerAccessCode"] = this.providerAccessCode;
        data["returnUrl"] = this.returnUrl;
        data["singleSignIn"] = this.singleSignIn;
        return data;
    }
  }