import { HttpDataSource } from "../data_source/config";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Integer } from "../helpers/integer";
import { SessionService } from "./session.service";
import { AircraftPositioningModel } from "../../shared/model/aircraft-positioning.model";
import { copyPasteResponse } from "../../shared/model/http/responses/copy-paste-responde";
import { FilterAircraftPositioningRequest } from "src/app/shared/model/http/requests/filter-aircraft-positioning-request";
import { MaintenanceObservationsRequest } from "src/app/shared/model/http/requests/maintenance-observations-request";

@Injectable({ providedIn: 'root' })
export class AircraftPositioningService {
  constructor(
    private dataSource: HttpDataSource,
    private sessionService: SessionService
  ) {
  }

  postLastFifthPending(aircraftPositioningRequest: FilterAircraftPositioningRequest) {
    return this.dataSource.post<AircraftPositioningModel[]>(`${environment.apiUrl}/AircraftPositioning/PositioningPendingSearch/`, aircraftPositioningRequest, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  postLastFifthConcluded(aircraftPositioningRequest: FilterAircraftPositioningRequest) {
    return this.dataSource.post<AircraftPositioningModel[]>(`${environment.apiUrl}/AircraftPositioning/PositioningConcludeSearch/`, aircraftPositioningRequest, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  getById(aircraftPositioningId: number) {
    return this.dataSource.get<AircraftPositioningModel>(`${environment.apiUrl}/AircraftPositioning/${aircraftPositioningId}`, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  copyPaste(aircraftPositioningId: number) {
    return this.dataSource.get<copyPasteResponse>(`${environment.apiUrl}/AircraftPositioning/CopyPaste/${aircraftPositioningId}`, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  add(AircraftPositioningModel: AircraftPositioningModel): Observable<AircraftPositioningModel> {
    return this.dataSource.post<AircraftPositioningModel>(`${environment.apiUrl}/AircraftPositioning`, AircraftPositioningModel, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  update(AircraftPositioningModel: AircraftPositioningModel): Observable<AircraftPositioningModel> {
    return this.dataSource.put<AircraftPositioningModel>(`${environment.apiUrl}/AircraftPositioning`, AircraftPositioningModel, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  delete(aircraftPositioningId: number): Observable<AircraftPositioningModel> {
    return this.dataSource.delete<AircraftPositioningModel>(`${environment.apiUrl}/AircraftPositioning/${aircraftPositioningId}`, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  adherenceBaseChange(aircraftPositioningId: number, reasonBaseChangeId: number, basesMaintenanceId: number) {
    return this.dataSource.put<AircraftPositioningModel>(`${environment.apiUrl}/AircraftPositioning/adherenceBaseChange?aircraftPositioningId=${aircraftPositioningId}&reasonBaseChangeId=${reasonBaseChangeId}&basesMaintenanceId=${basesMaintenanceId}`, aircraftPositioningId, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  adherenceCancellation(aircraftPositioningId: number, reasonCancellationId: number, descriptionCancellation: string){
    return this.dataSource.put<AircraftPositioningModel>(`${environment.apiUrl}/AircraftPositioning/adherenceCancellation?aircraftPositioningId=${aircraftPositioningId}&reasonCancellationId=${reasonCancellationId}&descriptionCancellation=${descriptionCancellation}`, aircraftPositioningId, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  adherenceNotPlacing(aircraftPositioningId: number, reasonNotPlacingId: string, descriptionCco: string){
    return this.dataSource.put<AircraftPositioningModel>(`${environment.apiUrl}/AircraftPositioning/adherenceNotPlacing?aircraftPositioningId=${aircraftPositioningId}&reasonNotPlacingId=${reasonNotPlacingId}&descriptionCco=${descriptionCco}`, aircraftPositioningId, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  updateLastEdition(aircraftPositioningId: number) {
    return this.dataSource.put<AircraftPositioningModel>(`${environment.apiUrl}/AircraftPositioning/updateLastEdition/${aircraftPositioningId}`, aircraftPositioningId,{ headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  permissionTimeEdit(aircraftPositioningId: number) {
    return this.dataSource.get<AircraftPositioningModel>(`${environment.apiUrl}/AircraftPositioning/PermissionTimeEdit/${aircraftPositioningId}`, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  reschedule(aircraftPositioningId: number) {
    return this.dataSource.put(`${environment.apiUrl}/AircraftPositioning/RescheduleCancellation/${aircraftPositioningId}`, {}, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*' } })
  }
  
  approveChanges(aircraftPositioningId: number) {
    return this.dataSource.put(`${environment.apiUrl}/AircraftPositioning/ApproveChanges/${aircraftPositioningId}`, {}, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*' } })
  }

  getAllReasons() {
    return this.dataSource.get<any>(`${environment.apiUrl}/AircraftPositioning/AllReason`, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*'  } });
  }

  changeCheckMaintenance(listAircraftPositioning: any) {
    return this.dataSource.put(`${environment.apiUrl}/AircraftPositioning/CheckMaintenance`, listAircraftPositioning, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*' } })
  }

  changeChecCoordination(listAircraftPositioning: any) {
    return this.dataSource.put(`${environment.apiUrl}/AircraftPositioning/CheckCoordination`, listAircraftPositioning, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*' } })
  }

  getMaintenanceObservations(maintenanceObservations: MaintenanceObservationsRequest) {
    return this.dataSource.put(`${environment.apiUrl}/AircraftPositioning/MaintenanceObservations`, maintenanceObservations, { headers: { 'Authorization': `Bearer ${this.sessionService.getToken()}`, 'Access-Control-Allow-Origin': '*' } })
  }
}
