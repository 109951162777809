import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-modal-detail-daily-check',
  templateUrl: './modal-detail-daily-check.component.html',
  styleUrls: ['./modal-detail-daily-check.component.scss'],
})
export class ModalDetailDailyCheckComponent {

  dadosAtual: any;

  constructor(
    public dialogRefDailyCheck: MatDialogRef<ModalDetailDailyCheckComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {  }

  onNoClick(): void {
    this.dialogRefDailyCheck.close();
  }

  onClick(): void {
    this.dialogRefDailyCheck.close(true);
  }
}
